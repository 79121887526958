.Dashboard {
    display: flex;
    justify-content: space-between;
    background: #fdfffc;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.LoadingMessage {
    padding: 3vmin 6vmin 8vmin 6vmin;
}

.LoadingMessage p {
    margin-bottom: 10vmin;
}

.LeftMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 75px;
    height: calc(100vh + 10px);
    border-right: 1px solid rgb(231, 231, 231);
}

.menu {
    margin-top: 40px;
    cursor: pointer;
}

.linkedin {
    margin-bottom: 40px;
    cursor: pointer;
}

.Constructor {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.RightMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid rgb(231, 231, 231);
    width: 75px;
    padding: 5px;
    min-height: 100vh;
}

.Header {
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    text-align: start;
    margin: 10px 40px;
    border-bottom: 1px solid rgb(231, 231, 231);
}

.Header span {
    font-size: 4vmin;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-top: -20px;
}

.Header p {
    cursor: pointer;
    width: max-content;
    font-size: 1.5vmin;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-top: -20px;
    text-decoration: overline;
    margin-left: 5px;
}

.green {
    color: #55AD9C;
}

.HelperInfo {
    margin-top: 40px;
    font-size: 16px;
}

.Body img {
    height: 90px;
}

.cost {
    display: none;
    color: #3fbba2;
    margin-left: 7px;
    padding: 0px;
    left: 0;
    font-weight: bold;
    right: 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    font-style: italic;
}

.Header img {
    height: 12vmin;
    width: 12vmin;
}

.Body {
    display: flex;
    width: 100%;
    margin-top: -20px;
}

.RightBodyHeader {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(231, 231, 231);
}

/* At the top level of your code */
@media screen and (max-width: 600px) {
    .Body {
        flex-wrap: wrap;
    }

    .RightBodyPart {
        width: calc(100vw - 150px);
        padding: 40px;
        border-left: none;
    }

    .TableCard {
        height: 100vh;
        width: 100%;
    }

    .CardBackground {
        width: 100%;
        height: 100vh;
    }
}

@media screen and (min-width: 600px) {
    .RightBodyPart {
        flex: 50%;
        padding: 0px 40px 40px 40px;
        border-left: 1px solid rgb(231, 231, 231);
    }
}

.LeftBodyPart {
    flex: 50%;
    padding: 0px 40px 40px 40px;
    display: flex;
    flex-wrap: wrap;
}

.ParamItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px;
}

.ParamItem img {
    width: 250px;
}

.ItemOptions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.CheckOption {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.EmptyList {
    width: 100%;
    text-align: start;
    font-style: italic;
}

.CheckOption input {
    margin-right: 10px;
}

.FieldOption {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;
}

.ItemTitle {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.field {
    border-bottom: #1b1f23 2px solid;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-left: 20px;
    background: none;
    width: 200px;
    font-size: 16px;
}

.Footer {
    width: calc(100% - 30px);
    display: flex;
    justify-content: center;
    padding: 15px;
}

.Button {
    border: 2px solid #1b1f23;
    border-radius: 3px;
    padding: 10px 20px;
    font-weight: bold;
    background: #fff;
    text-decoration: none;
    margin: 8px;
    cursor: pointer;
}

.Button:hover {
    color: #fff;
    background: #1b1f23;
}

.Theme {
    border: #1b1f23 3px solid;
    border-radius: 5px;
    width: max-content;
    margin: 20px auto;
    cursor: pointer;
}

.Sun {
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.Moon {
    padding: 5px;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
}

.dark {
    background: #1b1f23;
    color: #fff;
}

.white {
    background: #fff;
    color: #1b1f23;
}

.Theme.white {
    border: #fff 3px solid;
}

.Button.dark {
    border: #fff 3px solid;
}

.field.dark {
    color: #fff;
    border-bottom: #fff 3px solid;
}

.Button.dark:hover {
    background: #fff;
    color: #1b1f23;
}

.Footer.dark {
    background: #262a2d;
}

.Footer.white {
    background: #ecf2f2;
}

.InfoCard {
    position: absolute;
    background: #55AD9C;
    height: 100vh;
    width: 100%;
    animation-name: fadeInDown;
    animation-duration: 1s;
}

.InfoCardConstructor {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: 80px;
}

.InfoCardConstructor .green {
    color: white;
}

.InfoCardConstructor .Header {
    border-bottom: none;
    align-items: center;
}

.InfoCardConstructor .Header span {
    margin-top: 0px;
}

.Info {
    margin-bottom: 40px;
}

.InfoCardConstructor .Body {
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    width: 320px;
    font-style: italic;
    margin-left: 40px;
    margin-right: 40px;
}

.InfoCardConstructor .Body a {
    color: #000;
    font-weight: bold;
    margin-left: 1px;
    margin-right: 1px;
    text-decoration: none;
}

.InfoButton {
    font-style: normal;
    font-size: 20px;
    padding: 5px;
    margin-left: 60px;
}

.InfoButton a:hover {
    color: #fff;
}

.InfoCard svg {
    height: 40px;
    width: 40px;

    -webkit-animation: spin 1s linear;
    -moz-animation: spin 1s linear;
    animation: spin 1s linear;
}

@keyframes fadeInDown {
    0% {
       transform: translateY(-100vh);
    }
    100% {
       transform: translateY(0);
    }
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}

@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}

@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.ShareCard.white {
    height: max-content;
    width: 500px;
    margin: auto;
    background: #fff;
    border: #fff 1px solid;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}

.ShareCard.dark {
    height: max-content;
    width: 500px;
    margin: auto;
    background: #1b1f23;
    border: #1b1f23 1px solid;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}

.ShareCardTop {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid rgb(231, 231, 231);
    margin-bottom: 20px;
}

.ShareTitle {
    font-size: 22px;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.ShareBody {
    display: flex;
    flex-direction: column;
}

.ShareBody .field {
    margin-top: 20px;
    width: calc(100% - 45px);
}

.ShareInfo {
    display: flex;
    flex-wrap: wrap;
}

.ShareCardBottom {
    display: flex;
    justify-content: center;
    border-top: 1px solid rgb(231, 231, 231);
    padding: 10px;
    margin-top: 20px;
}

.TableCard.white {
    height: 600px;
    width: 800px;
    margin: auto;
    background: #fff;
    border: #fff 1px solid;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}

.TableCard.dark {
    height: 600px;
    width: 800px;
    margin: auto;
    background: #1b1f23;
    border: #1b1f23 1px solid;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}

.CardBackground {
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    position: absolute;
    background: #7eaaa138;
}

.CardHeader {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.CardHeader svg {
    padding: 10px;
    cursor: pointer;
}

.CardHeader span {
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.ColumnHeader {
    display: flex;
    margin-top: 10px;
}

.ColumnHeader select {
    padding: 0px 10px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #1b1f23;
    font-weight: bold;
    font-size: 15px;
    margin-left: 10px;
}

.ColumnHeader select.dark {
    border-bottom: 3px solid #fff;
}

.ColumnHeader .field {
    font-size: 15px;
    font-weight: bold;
    padding-left: 20px;
    width: 110px;
}

.ColumnHeader .Button {
    padding: 5px 7px;
    margin-left: 20px;
    margin-bottom: -0px;
}

.ColumnList {
    background: #262a2d11;
    height: 100%;
    margin: 20px;
    overflow-y: scroll;
}

.ColumnList.dark {
    background: #f1f1f125;
    height: 100%;
    margin: 20px;
}

.ColumnBottom {
    align-self: flex-end;
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: auto;
    margin-right: 10px;
}

.ColumnBottom .Button {
    width: max-content;
}

.columnIssue {
    margin-top: 10px;
    padding: 6px 10px;
    width: max-content;
    margin-left: 15px;
    border-radius: 4px;
    background: rgba(255, 247, 0, 0.733);
}

.columnRow {
    display: flex;
    padding: 10px;
    align-items: center;
    background: #53a26c3c;
    margin: 5px;
    flex-direction: row;
}

.columnRow .Button {
    font-size: 10px;
    padding: 5px 10px;
}

.columnRow span {
    margin-right: 10px;
    min-width: 100px;
}

.ColumnBottom .field {
    margin-bottom: 8px;
    padding: 10px;
    margin-right: 10px;
    font-size: 20px;
}

.TableRow {
    display: flex;
    padding: 10px;
    align-items: center;
    background: #53a26c3c;
    margin: 5px;
    flex-direction: row;
}

.TableRow .Button {
    font-size: 10px;
    padding: 5px 10px;
}

.TableRow span {
    margin-right: 10px;
    min-width: 100px;
}

.tip {
    width: 100px;
    background: #55ad9bc4;
    color: #fff;
    padding: 5px;
    margin-top: 5px;
    border-radius: 4px;
    font-size: 12px;
}

.ListOfTables {
    overflow-y: scroll;
    max-height: 450px;
}

.Loader-dark {
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 6vmax;
    height: 6vmax;
    border-right: 6px solid rgb(255, 255, 255);
    border-radius: 100%;
    animation: spinRight 800ms linear infinite; 
}

.Loader-white {
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 6vmax;
    height: 6vmax;
    border-right: 6px solid rgb(0, 0, 0);
    border-radius: 100%;
    animation: spinRight 800ms linear infinite; 
}
  
@keyframes spinLeft {
    from { 
        transform:rotate(0deg);
    }
    to {
        transform:rotate(720deg); 
    }
}
  
@keyframes spinRight {
    from {
        transform:rotate(360deg);
    }
    to {
        transform:rotate(0deg);
    }
}