.PaymentPopup {
    width: 100%;
    height: 102vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(0, 0, 0, 0.625);
}

.PaymentMethods {
    display: flex;
    flex-direction: column;
    min-width: 350px;
    margin: auto;
    max-width: 331px;
    background: white;
    box-shadow: 0 0 40px rgba(16,57,95,.08);
    padding: 30px;
    height: max-content;
}

.ChooseText {
    width: 100%;
    display: flex;
    justify-content: start;
    color: #838383;
    margin: 8px 0 8px 8px;
}

.PaymentMethods button {
    border: 2px solid #1b1f23;
    border-radius: 3px;
    padding: 10px 20px;
    font-weight: bold;
    background: #fff;
    text-decoration: none;
    margin: 8px;
    cursor: pointer;
}

.PaymentMethods button:hover {
    color: wheat;
    background: #1b1f23;
}

.XIcon {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.CardTitle {
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 27px;
    margin-bottom: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.LogoIcon {
    width: 60px;
    height: 65px;
}

.CloseIcon {
    width: 20px;
    height: 15px;
    cursor: pointer;
}

.stock-monitor-container {
    position: relative;
    width: 100%;
    height: 35px;
    margin-top: 20px;
    overflow: hidden;
}
  
.stock-monitor {
    position: absolute;
    width: 200%;
    height: 100%;
    background-image: url("https://t3.ftcdn.net/jpg/04/07/44/62/360_F_407446284_KBEUJeBQWXwrPwRB7vh0CdAzT9VmXori.jpg");
    background-size: contain;
    background-position: right center;
    animation: stock-monitor-animation 10s linear infinite;
}
  
.stock-monitor::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
}
  
@keyframes stock-monitor-animation {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-50%);
    }
}